<script setup>
// import { RouterLink, RouterView } from "vue-router";
</script>

<template>
	<Menu v-if="$route.name !== 'login'" />

	<div id="content" :class="['d-flex', 'flex-column', $route.name !== 'login' ? 'p-15' : 'p-0']">
		<router-view></router-view>
	</div>
	<Footer />
</template>
<script>
import Menu from "./components/globalVue/Menu.vue";
import Footer from "./components/globalVue/Footer.vue";
export default {
	name: "App",
	components: {
		Menu,
		Footer,
	},
	data() {
		return {};
	},
	methods: {},
};
</script>

<style>
@import "@/assets/css/app.css";
@import "@/assets/css/icon.css";
</style>
