<template>
	<footer class="footer fixed-bottom bg-light">
		<div class="container">
			<p class="col-auto me-auto mt-2" style="display: flex; float: left; align-items: center; color: #737373">
				<i class="icon-logo-text" style="font-size: 22px; margin-right: 10px"></i>
				copyright &copy; <span class="date">{{ date }}</span>
			</p>
			<p class="col-auto mt-2" style="float: right"><a href="https://www.dynvibe.com" rel="external">www.dynvibe.com</a></p>
		</div>
	</footer>
</template>

<script>
export default {
	name: "Footer",
	data() {
		return {
			date: "",
		};
	},
	created() {
		this.date = new Date().getFullYear();
	},
};
</script>

<style scoped></style>
