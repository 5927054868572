<template>
	<header id="sidebar" class="d-flex flex-column flex-shrink-0 navbar-fixed-left active margin-t-bar">
		<div class="sidebar-header">
			<img class="img-responsive dynvibeLogo" style="height: 80%" src="@/assets/img/favicon.png" alt="Dynvibe" />
		</div>
		<ul class="menu-admin nav nav-pills nav-flush flex-column mb-auto text-center">
			<li class="nav-item">
				<router-link to="/search" class="submenu">
					<div class="icon-menu-admin">
						<img class="img-responsive menuLogo" src="@/assets/img/google-icon.svg" alt="Search" />
					</div>
					<span>Trends</span>
				</router-link>
				<router-link to="/google-ads" class="submenu"><span>Google Ads</span></router-link>
				<router-link to="/dashboard" class="submenu"><span>Searches</span></router-link>
			</li>
			<!-- <li class="nav-item">
				<router-link to="/social-networks">
					<div class="icon-menu-admin">
						<img class="img-responsive menuLogo" src="@/assets/img/tiktok-icon.svg" alt="Search" />
					</div>
					<span>Trends</span></router-link
				>
			</li> -->
			<li class="nav-item">
				<a style="color: darkred; cursor: pointer" @click="logout()">
					<div class="icon-menu-admin">
						<font-awesome-icon icon="door-open" />
					</div>
					<span>Logout</span>
				</a>
			</li>
		</ul>
	</header>
</template>

<script>
export default {
	name: "Menu",
	components: {},
	props: {},
	data() {
		return {
			env: "",
		};
	},
	mounted() {
		this.env = process.env.NODE_ENV;
	},
	methods: {
		logout() {
			this.$store.dispatch("setJwt", "");
			this.$store.dispatch("setUsername", "");

			window.location.href = "/login";
		},
	},
};
</script>
<style scoped>
.router-link-active {
	color: #33d1cd;
}

.submenu {
	padding-top: 0% !important;
	padding-bottom: 7px !important;
}

#sidebar {
	position: fixed;
	height: 100%;
	width: 300px;
}

#sidebar:not(.active) .menu-admin div.icon-menu-admin {
	display: inline-block;
	width: 20px;
	font-family: Montserrat;
}

#sidebar:not(.active) ul li a {
	font-size: 1em;
}

#sidebar:not(.active) svg {
	width: 14px;
}

.menu-admin > li > a > svg {
	margin-right: 7px;
	margin-left: 0;
}

#sidebar.active ul li a {
	margin-right: 0;
	margin-left: 0;
	margin-bottom: 8px;
}

#sidebar ul li a i,
#sidebar ul li a .svg-inline--fa.fa-w-18 {
	margin: 0;
}

#sidebar.active ul li a .svg-inline--fa.fa-w-18 {
	margin-left: 33px !important;
}

#sidebar.active ul li a {
	padding: 12px 15px 15px 15px;
}

#sidebar.active ul li img {
	padding: 6px 15px 8px 15px;
}

#sidebar .menuLogo {
	max-width: 60px;
	max-height: 60px;
}
</style>
