import Qs from "qs";
import { useMainStore } from "../stores";

import confirm from "@/components/globalVue/Confirm.vue";
import gselect from "@/components/globalVue/Select.vue";

const mixin = {
	components: { confirm, gselect },
	mounted() {
		let parameters = {
			...this.$store.parameters,
		};
		this.loadComponent(parameters);
	},
	data() {
		return {
			dialogLoad: null,
			confirmColor: "",
			confirmTitle: "",
			confirmType: "",
			confirmMessage: "",
			languageslist: [
				{ id: "ar", value: "Arabic" },
				{ id: "zh-CN", value: "Chinese (Simplified)" },
				{ id: "zh-TW", value: "Chinese (Traditional)" },
				{ id: "en", value: "English" },
				{ id: "fr", value: "French" },
				{ id: "de", value: "German" },
				{ id: "el", value: "Greek" },
				{ id: "it", value: "Italian" },
				{ id: "ja", value: "Japanese" },
				{ id: "ko", value: "Korean" },
				{ id: "pt", value: "Portuguese" },
				{ id: "pt-br", value: "Portuguese - Brazil" },
				{ id: "ru", value: "Russian" },
				{ id: "es", value: "Spanish" },
			],
			countrieslist: [
				{ id: "ar", value: "Argentina" },
				{ id: "au", value: "Australia" },
				{ id: "at", value: "Austria" },
				{ id: "be", value: "Belgium" },
				{ id: "br", value: "Brazil" },
				{ id: "ca", value: "Canada" },
				{ id: "cl", value: "Chile" },
				{ id: "cn", value: "China" },
				{ id: "co", value: "Colombia" },
				{ id: "cu", value: "Cuba" },
				{ id: "cz", value: "Czech Republic" },
				{ id: "da", value: "Denmark" },
				{ id: "eg", value: "Egypt" },
				{ id: "fr", value: "France" },
				{ id: "de", value: "Germany" },
				{ id: "gr", value: "Greece" },
				{ id: "hk", value: "Hong-Kong" },
				{ id: "hu", value: "Hungary" },
				{ id: "in", value: "India" },
				{ id: "id", value: "Indonesia" },
				{ id: "ie", value: "Ireland" },
				{ id: "il", value: "Israel" },
				{ id: "it", value: "Italy" },
				{ id: "jp", value: "Japan" },
				{ id: "kr", value: "Korea" },
				{ id: "mx", value: "Mexico" },
				{ id: "ma", value: "Morocco" },
				{ id: "nl", value: "Netherlands" },
				{ id: "nz", value: "New-Zealand" },
				{ id: "no", value: "Norway" },
				{ id: "pl", value: "Poland" },
				{ id: "pt", value: "Portugal" },
				{ id: "qa", value: "Qatar" },
				{ id: "ru", value: "Russia" },
				{ id: "sa", value: "Saudi Arabia (السعودية)" },
				{ id: "sg", value: "Singapore" },
				{ id: "za", value: "South Africa" },
				{ id: "es", value: "Spain" },
				{ id: "se", value: "Sweden" },
				{ id: "ch", value: "Switzerland" },
				{ id: "tw", value: "Taiwan" },
				{ id: "th", value: "Thailand" },
				{ id: "tr", value: "Turkey" },
				{ id: "gb", value: "UK" },
				{ id: "ua", value: "Ukraine" },
				{ id: "ae", value: "United Arab Emirates (الإمارات)" },
				{ id: "us", value: "USA" },
				{ id: "ve", value: "Venezuela" },
				{ id: "vn", value: "Vietnam" },
			],
			graphColor: [
				"#000080",
				"#EF402E",
				"#99C584",
				"#A60CA6",
				"#E35656",
				"#F3A344",
				"#7CB5EC",
				"#99C584",
				"#FFD500",
				"#EFBBFF",
				"#0457A9",
				"#FF0081",
				"#8D864A",
				"#6C4111",
				"#D9D4A5",
				"#232323",
				"#460446",
				"#11520D",
				"#F1C40F",
				"#A0D6B4",
				"#36802D",
				"#243F5B",
				"#8D864A",
				"#845422",
				"#4BCC2E",
				"#BE29EC",
				"#FF0081",
				"#0C457D",
				"#036",
				"#FFDAB9",
				"#DAA520",
				"#F66",
				"#66CDAA",
				"#FFF68F",
				"#B0E0E6",
				"#8A2BE2",
				"#696966",
				"#000",
			],
			graphColorUnknown: "#C4C4C4",
			periodsList: [
				{ id: "1m", value: "Last Month" },
				{ id: "3m", value: "Last 3 months" },
				{ id: "6m", value: "Last 6 months" },
				{ id: "1y", value: "Last year" },
				{ id: "2y", value: "Last 2 years" },
				{ id: "5y", value: "Last 5 years" },
			],
			// pendingAxios: false,
		};
	},
	props: {},
	methods: {
		loadComponent(/*parameters*/) {
			//console.log(parameters);
			// To be overwritten
		},
		getData(url, params = {}, condition = "", arrayFormat = "brackets") {
			if (url === "") {
				return;
			}
			const mainStore = useMainStore();
			params["jwt"] = mainStore.jwt;
			params.user = this.$store.username;

			this.$axios
				.get(this.$backendUrl + url, {
					params: params,
					paramsSerializer: function (params) {
						return Qs.stringify(params, {
							arrayFormat: arrayFormat,
						});
					},
				})
				.then((response) => {
					this.treatResponse(response, condition);
				})
				.catch((error) => {
					//catchError;
					this.catchError(error);
					if (this.$axios.isCancel(error)) {
						console.log("Request canceled", error.message);
						return;
					}
					console.log("Error axios getData " + url);
					console.log(error);
				});
		},
		postData(url, params = [], condition = "", catchError = {}) {
			const mainStore = useMainStore();

			let p = params;
			if (!(params instanceof FormData)) {
				params["jwt"] = mainStore.jwt;
				p = Qs.stringify(params);
			} else {
				p.append("jwt", mainStore.jwt);
			}

			this.$axios
				.post(this.$backendUrl + url, p)
				.then((response) => {
					this.pendingAxios = false;
					this.treatPostResponse(response, condition);
				})
				.catch((error) => {
					console.log(error);
					if (this.$axios.isCancel(error)) {
						console.log("Request canceled", error.message);
						return;
					}
					catchError;
					console.log("Error axios postData");
					console.log(error);
				});
		},
		prepareParams(indexCountry, indexKeyword = undefined) {
			if (indexCountry === 0) {
				return { ...this.params };
			}
			indexCountry -= 1; // v-for starts with an initial value of 1 instead of 0.
			if (!this.params || Object.keys(this.params).length === 0) {
				return {};
			}
			let params = { ...this.params };
			params.countries = params.countries[indexCountry];

			if (indexKeyword !== undefined && indexKeyword !== 0) {
				indexKeyword -= 1; // v-for starts with an initial value of 1 instead of 0.
				params.queries = [params.queries[indexKeyword]];
			}
			return params;
		},
		// storeWatcher(newValue, oldValue) {
		// 	return newValue.valueOf() !== oldValue.valueOf();
		// },
		confirmLoadingModal(status = "close", message = "In progress...") {
			this.confirmColor = "orange";
			this.confirmTitle = message;
			this.confirmType = "loading";
			if (this.$refs.confirm) {
				status === "close" ? this.$refs.confirm.close() : this.$refs.confirm.open();
			}
		},
		confirmInputAddModal(message) {
			this.confirmColor = "orange";
			this.confirmTitle = "Edit";
			this.confirmType = "edit";
			this.confirmMessage = message;
			if (this.$refs.confirm) {
				this.$refs.confirm.open();
			}
		},
		confirmInputDeleteModal(message) {
			this.confirmColor = "red";
			this.confirmTitle = "Delete";
			this.confirmType = "edit";
			this.confirmMessage = message;
			if (this.$refs.confirm) {
				this.$refs.confirm.open();
			}
		},
		confirmErrorModal(message) {
			this.confirmLoadingModal();
			this.confirmColor = "red";
			this.confirmTitle = "ERROR";
			this.confirmType = "alert";
			this.confirmMessage = message;
			if (this.$refs.confirm) {
				this.$refs.confirm.open();
			}
		},
		confirmSuccessModal(message) {
			this.confirmLoadingModal();
			this.confirmColor = "green";
			this.confirmTitle = "SUCCESS";
			this.confirmType = "alert";
			this.confirmMessage = message;
			if (this.$refs.confirm) {
				this.$refs.confirm.open();
			}
		},
		// darkenLightenColor(color, percent) {
		// 	if (typeof color !== "undefined") {
		// 		let num = parseInt(color.replace("#", ""), 16),
		// 			amt = Math.round(2.55 * percent),
		// 			R = (num >> 16) + amt,
		// 			B = ((num >> 8) & 0x00ff) + amt,
		// 			G = (num & 0x0000ff) + amt;
		// 		return (
		// 			"#" +
		// 			(
		// 				0x1000000 +
		// 				(R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
		// 				(B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
		// 				(G < 255 ? (G < 1 ? 0 : G) : 255)
		// 			)
		// 				.toString(16)
		// 				.slice(1)
		// 		);
		// 	}
		// 	return color;
		// },
		countryName(idCountry) {
			if (idCountry === "") {
				return "";
			}
			let obj = this.countrieslist.find((o) => o.id === idCountry);
			return obj !== undefined ? obj.value : "";
		},
		langName(idLang) {
			if (idLang === "") {
				return "";
			}
			let obj = this.languageslist.find((o) => o.id === idLang);
			return obj !== undefined ? obj.value : "";
		},
		formatPositive(value) {
			return value > 0 ? "+" + value : value;
		},
		formatToKM(value) {
			if (Math.abs(value) > 999 && Math.abs(value) < 1000000) {
				return (value / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
			} else if (Math.abs(value) >= 1000000 && Math.abs(value) < 1000000000) {
				return (value / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
			} else if (Math.abs(value) >= 1000000000) {
				return (value / 1000000000).toFixed(1) + "B"; // convert to B for number from > 1 billion
			} else {
				return value;
			}
		},
		formatToKMPercent(value) {
			return value > 0 ? "+" + this.formatToKM(value) + " %" : this.formatToKM(value) + " %";
		},
		// return title and topic for an object like { mid : 'toto', title: 'titi', type:'mytype' }
		getTitleAndTopic(searchObject) {
			if (searchObject === undefined || searchObject === "") {
				return "";
			}
			let type = searchObject.type !== undefined ? searchObject.type : "";
			let title = searchObject.title !== undefined ? searchObject.title : "";

			return type !== "" ? title + " - " + type : title;
		},
		// return only title  for an object like { mid : 'toto', title: 'titi', type:'mytype' }
		getTitle(searchObject) {
			if (searchObject === undefined || searchObject === "") {
				return "";
			}
			if (searchObject.title === undefined) {
				//console.log("missing title in object");
				return "";
			}
			return searchObject.title;
		},
		// return a period like 5y, 2y, 6m ... for two dates
		getPeriod(gte, lte) {
			let dateStart = this.$moment(gte);
			let dateEnd = this.$moment(lte);
			// Note : if value is undefined, moment will give the current day

			if (!dateStart.isValid() || !dateEnd.isValid()) {
				return "0m";
			}
			let interval = dateEnd.diff(dateStart, "years");
			if (interval > 0) {
				return interval + "y";
			}

			interval = dateEnd.diff(dateStart, "months");
			return interval + "m";
		},
	},
};

export default mixin;
