import { createApp } from "vue";
import { useMainStore } from "./stores";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
// TEST CI
// TEST CI
// import "@/assets/css/front/icon.css";
// import "@/assets/css/flag-icon.min.css";
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fab);
import { far } from "@fortawesome/free-regular-svg-icons";
library.add(far);
dom.watch();
import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";
import More from "highcharts/highcharts-more";
import stockInit from "highcharts/modules/stock";
import exportingInit from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import accesibility from "highcharts/modules/accessibility";
//import loadIndicatorsAll from "highcharts/indicators/indicators-all";

exportingInit(Highcharts);
More(Highcharts);
stockInit(Highcharts);
exportData(Highcharts);
accesibility(Highcharts);
//loadIndicatorsAll(Highcharts);

import App from "./App.vue";
import router from "./router";

const app = createApp(App);

const moment = require("moment");
import axios from "axios";
import Qs from "qs";

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$Qs = Qs;
app.config.globalProperties.$moment = moment;

if (process.env.NODE_ENV == "development") {
	app.config.globalProperties.$backendUrl = "http://dev.figures.com/";
} else if (process.env.NODE_ENV == "staging") {
	app.config.globalProperties.$backendUrl = "https://stagingbackfigures.dynvibe.com/";
	window.markerConfig = {
		destination: "62ebf1e1c743ecee3707fcbc",
		source: "snippet",
	};
	!(function (e, r /*a*/) {
		if (!e.__Marker) {
			e.__Marker = {};
			var t = [],
				n = { __cs: t };
			[
				"show",
				"hide",
				"isVisible",
				"capture",
				"cancelCapture",
				"unload",
				"reload",
				"isExtensionInstalled",
				"setReporter",
				"setCustomData",
				"on",
				"off",
			].forEach(function (e) {
				n[e] = function () {
					var r = Array.prototype.slice.call(arguments);
					r.unshift(e), t.push(r);
				};
			}),
				(e.Marker = n);
			var s = r.createElement("script");
			(s.async = 1), (s.src = "https://edge.marker.io/latest/shim.js");
			var i = r.getElementsByTagName("script")[0];
			i.parentNode.insertBefore(s, i);
		}
	})(window, document);
} else if (process.env.NODE_ENV == "test") {
	app.config.globalProperties.$backendUrl = "https://stagingbackfigures.dynvibe.com/";
} else {
	app.config.globalProperties.$backendUrl = "https://apifigures.dynvibe.com/";
}

if (["production", "staging"].includes(process.env.NODE_ENV)) {
	window.markerConfig = {
		destination: "62ebf1e1c743ecee3707fcbc",
		source: "snippet",
	};
	!(function (e, r /*a*/) {
		if (!e.__Marker) {
			e.__Marker = {};
			var t = [],
				n = { __cs: t };
			[
				"show",
				"hide",
				"isVisible",
				"capture",
				"cancelCapture",
				"unload",
				"reload",
				"isExtensionInstalled",
				"setReporter",
				"setCustomData",
				"on",
				"off",
			].forEach(function (e) {
				n[e] = function () {
					var r = Array.prototype.slice.call(arguments);
					r.unshift(e), t.push(r);
				};
			}),
				(e.Marker = n);
			var s = r.createElement("script");
			(s.async = 1), (s.src = "https://edge.marker.io/latest/shim.js");
			var i = r.getElementsByTagName("script")[0];
			i.parentNode.insertBefore(s, i);
		}
	})(window, document);
}
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

import mixin from "@/mixins/mixin.js";
app.mixin(mixin);
app.component("FontAwesomeIcon", FontAwesomeIcon);

Highcharts.setOptions({
	lang: {
		numericSymbols: [" k", " M", " B"],
	},
});

app.use(pinia);
app.use(router);
app.use(HighchartsVue);

app.config.globalProperties.$store = useMainStore(pinia); // need to be call here

// Cancel Token Request Interceptor
axios.interceptors.request.use(
	function (config) {
		//  Generate cancel token source
		let source = axios.CancelToken.source();

		// Set cancel token on this request
		config.cancelToken = source.token;

		// Add to vuex to make cancellation available from anywhere
		app.config.globalProperties.$store.dispatch("ADD_CANCEL_TOKEN", source);

		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);
app.mount("#app");
