import { createRouter, createWebHistory } from "vue-router";
import axios from "axios";

import { useMainStore } from "../stores";
import Qs from "qs";

function checkToken(to, from, next) {
	const mainStore = useMainStore();
	let backendUrl = "";
	if (process.env.NODE_ENV == "development") {
		backendUrl = "http://dev.figures.com/";
	} else if (process.env.NODE_ENV == "staging") {
		backendUrl = "https://stagingbackfigures.dynvibe.com/";
	} else {
		backendUrl = "https://apifigures.dynvibe.com/";
	}
	mainStore.dispatch("CANCEL_PENDING_REQUESTS");
	axios
		.post(
			backendUrl + "checkJwtToken",
			Qs.stringify({
				jwt: mainStore.jwt,
				username: mainStore.username,
			})
		)
		.then((response) => {
			if (response.data.logged == true && response.data.token) {
				mainStore.dispatch("setJwt", response.data.token);
				next();
			} else {
				mainStore.dispatch("setJwt", "");
				mainStore.dispatch("setUsername", "");
				window.location.href = "/login";
			}
		})
		.catch(() => {
			mainStore.dispatch("setJwt", "");
			mainStore.dispatch("setUsername", "");
			window.location.href = "/login";
		});
}

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes: [
		{
			path: "/",
			redirect: "/dashboard",
		},
		{
			path: "/dashboard",
			name: "home",
			component: () => import("@/views/DashboardPage.vue"),
			beforeEnter: checkToken,
		},
		{
			path: "/login",
			name: "login", // route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import("@/views/LoginPage.vue"), // beforeEnter: checkToken,
		},
		{
			path: "/social-networks",
			name: "socialNetworks",
			component: () => import("@/views/SocialNetworks.vue"),
			beforeEnter: checkToken,
		},
		{
			path: "/search",
			name: "search",
			component: () => import("@/views/SearchPage.vue"),
			props: (route) => ({
				id: route.query.id,
			}),
			beforeEnter: checkToken,
		},
		{
			path: "/google-ads",
			name: "google-ads",
			component: () => import("@/views/GoogleAdsPage.vue"),
			beforeEnter: checkToken,
		},
		{
			path: "/count",
			name: "count",
			component: () => import("@/views/CountPage.vue"),
			beforeEnter: checkToken,
		},
	],
});

export default router;
