import { defineStore } from "pinia";

export const useMainStore = defineStore({
	id: "main",
	state: () => ({
		parameters: {},
		jwt: "",
		username: "",
		cancelTokens: [],
	}),
	actions: {
		CANCEL_PENDING_REQUESTS() {
			// Cancel all request where a token exists
			this.cancelTokens.forEach((request /*i*/) => {
				if (request.cancel) {
					request.cancel();
				}
			});

			// Reset the cancelTokens store
			this.cancelTokens = [];
		},
		addToParameters(payload) {
			if (this.parameters) {
				this.parameters[payload.key] = payload.value;
			}
		},
		removeFromParameters(key) {
			if (this.parameters) {
				delete this.parameters[key];
			}
		},
		setJwt(value) {
			this.jwt = value;
		},
		setUsername(value) {
			this.username = value;
		},
		ADD_CANCEL_TOKEN(token) {
			this.cancelTokens.push(token);
		},
		dispatch(action, value) {
			this[action](value);
		},
	},
	persist: true,
});
