<template>
	<div>
		<div v-if="showConfirm">
			<transition name="modal">
				<div class="modal-mask">
					<div class="modal-wrapper" @click="showConfirm = false">
						<div :class="getModalClass">
							<div class="modal-content" @click.stop>
								<div class="modal-header">
									<div class="title">
										{{ title }}
									</div>
								</div>
								<div class="modal-body">
									<div v-if="type !== 'loading'">
										<slot name="confirm-body"> Sample body </slot>
									</div>
									<div v-else>
										<div class="loader"></div>
									</div>
								</div>
								<div class="confirm-buttons">
									<slot name="confirm-buttons">
										<button
											v-if="type === 'edit'"
											type="button"
											class="btn btn-green"
											@click="$emit('answerconfirm', 'OK')"
										>
											Proceed
										</button>
										<button
											v-if="type === 'edit'"
											type="button"
											class="btn btn-default"
											@click="
												close();
												$emit('answerconfirm', 'Cancel');
											"
										>
											cancel
										</button>
										<button v-if="type === 'alert'" type="button" class="btn btn-default" @click="close()">OK</button>
									</slot>
								</div>
							</div>
						</div>
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
export default {
	name: "Confirm",

	props: {
		color: {
			type: String,
			default: "green",
		},
		title: {
			type: String,
			default: "Title",
		},
		size: {
			type: String,
			default: "modal-md",
		},
		type: {
			type: String,
			default: "edit",
		},
	},
	emits: ["answerconfirm"],
	data() {
		return {
			showConfirm: false,
		};
	},
	computed: {
		getModalClass() {
			let classNames = [];
			classNames.push("modal-dialog");
			classNames.push(this.size);

			if (this.color === "green") classNames.push("border-green");
			else if (this.color === "orange") classNames.push("border-orange");
			else if (this.color === "red") classNames.push("border-red");
			return classNames.join(" ");
		},
	},
	created() {
		//
	},
	methods: {
		open() {
			this.showConfirm = true;
		},
		close() {
			this.showConfirm = false;
		},
	},
};
</script>

<style scoped>
.modal-mask {
	position: fixed;
	z-index: 10000; /* 10000 to be above others modals */
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

.close {
	outline: 0;
}

.modal-dialog {
	-webkit-box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
	box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
	margin-left: 29%;
	margin-right: 29%;
}

.modal-content {
	border-radius: 0 0 4px 4px;
	padding: 30px 30px 15px;
	background-color: #fff;
}

.modal-header {
	border: 0;
}

.title {
	text-align: center;
	font-weight: bold;
	font-size: 24px;
	color: black;
	text-transform: none;
	font-family: "Montserrat";
	line-height: 20px;
}

.border-red {
	border-top: solid 7px #e74c3c;
	border-radius: 4px;
}

.border-orange {
	border-top: solid 7px #f1c40f;
	border-radius: 4px;
}

.border-green {
	border-top: solid 7px #2ecc71;
	border-radius: 4px;
}

.confirm-buttons {
	text-align: center;
	padding-top: 11px;
	padding-bottom: 30px;
}

.confirm-buttons button {
	font-weight: bold;
	text-transform: uppercase;
	-webkit-transition: background 0.1s;
	transition: background 0.1s;
	padding: 10px 20px;
}

.confirm-buttons button.btn-default {
	background-color: #ecf0f1;
	color: #000;
	text-shadow: none;
	-webkit-transition: background 0.2s;
	transition: background 0.2s;
}

.confirm-buttons button.btn-green {
	background-color: #2ecc71;
	color: #fff;
	text-shadow: none;
	-webkit-transition: background 0.2s;
	transition: background 0.2s;
}

button {
	margin-left: 5px;
	margin-right: 5px;
}

.modal-body {
	font-size: 15px;
	text-align: center;
	color: #777;
	/*margin-bottom: 25px;*/
	font-family: "Montserrat";
}

.loader {
	color: #f1c40f;
	font-size: 10px;
	margin: 50px auto;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	position: relative;
	text-indent: -9999em;
	-webkit-animation: load4 1.3s infinite linear;
	animation: load4 1.3s infinite linear;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
}

@-webkit-keyframes load4 {
	0%,
	100% {
		box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em,
			-2em -2em 0 0;
	}
	12.5% {
		box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
	}
	25% {
		box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em,
			-2em -2em 0 -1em;
	}
	37.5% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em,
			-2em -2em 0 -1em;
	}
	50% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em,
			-2em -2em 0 -1em;
	}
	62.5% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
	}
	75% {
		box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em,
			-2em -2em 0 0;
	}
	87.5% {
		box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
	}
}

@keyframes load4 {
	0%,
	100% {
		box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em,
			-2em -2em 0 0;
	}
	12.5% {
		box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
	}
	25% {
		box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em,
			-2em -2em 0 -1em;
	}
	37.5% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em,
			-2em -2em 0 -1em;
	}
	50% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em,
			-2em -2em 0 -1em;
	}
	62.5% {
		box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
	}
	75% {
		box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em,
			-2em -2em 0 0;
	}
	87.5% {
		box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
	}
}
</style>
